<template>
  <div class="card__container">
    <v-row
      no-gutters
      class="items__container card card-lift--hover border-0 mb-4"
      v-for="item in items"
      :key="item.id"
    >
      <v-col cols="12" sm="5" class="card__item-img">
        <router-link class="img-container" :to="'/publicaciones/' + item.slug">
          <v-img
            :aspect-ratio="16 / 9"
            :alt="item.name"
            v-if="item.cover && item.cover.route"
            class="card__img img-fluid"
            :src="item.cover.route"
          ></v-img>
        </router-link>
      </v-col>

      <v-col cols="12" sm="7" class="card__item-content">
        <div class="card__content-block">
          <router-link :to="'/publicaciones/' + item.slug"
            ><v-card-text style="position: relative">
              <h6 class="content__title my-auto">
                {{ item.title.slice(0, 80) }}
              </h6>
              <p class="text-muted" v-show="item.type !== 'events'">
                {{ item.dateText }}
              </p>
              <!-- <v-tooltip
                class=""
                v-show="item.countries && item.countries.length > 0"
                top
                v-for="country in item.countries"
                :key="country.id"
              >
                <template v-slot:activator="{ on, attrs }">
                  <img
                    class="content__country-flag"
                    v-bind="attrs"
                    v-on="on"
                    :src="country.flag"
                  /> </template
                ><span>{{ country.name }}</span></v-tooltip
              > -->
            </v-card-text>
          </router-link>

          <!-- <v-card-text class="content__description">
            {{ item.shortdesc }}
          </v-card-text> -->
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'PublicationsCardH',
    props: {
      items: {
        type: Array,
        required: false,
      },
    },
    data() {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
  // Responsive Variables
  $extra-large: 'only screen and (min-width: 1200px)';
  $large: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $medium: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $small: 'only screen and (min-width: 576px) and (max-width: 767px)';
  $extra-small: 'only screen and (max-width: 575px)';
  $extra-small: 'only screen and (max-width: 575px)';

  // Styles for Blog Post section

  .v-card__subtitle,
  .v-card__text,
  .v-content__title {
    padding: 5px;
  }

  .card-lift--hover:hover {
    transform: scale(1.03);
    transition: all 0.15s ease;
  }

  .items__container {
    margin: 0px 0px 10px 0px;
    min-height: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
  }
  .img-fluid {
    width: 100%;
    max-height: 100%;
  }
  .card__content-block {
    padding: 2%;
  }

  .content__title {
    //margin-top: 10px !important;
    font-size: 0.85rem;
    // height: 50px;
  }
  .text-muted {
    font-size: 0.75rem;
    margin-right: 10px;
    display: inline-block;
    text-transform: capitalize;
  }
  .description {
    height: 80px;
  }
  .content__country-flag {
    margin: 0 5px;
    width: 35px;
    height: 25px;
  }
  .events__info-container {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .card__item-content {
    padding: 0 !important;
  }
  .card__button-container {
    width: inherit;
    display: flex;
    justify-content: flex-end;
  }
  .card__button {
    justify-self: flex-end;
  }
  @media #{$small} {
    .card__container {
      margin: 0;
    }
    .card__button-container {
      padding: 0;
      width: inherit;
      display: flex;
      justify-content: flex-end;
    }
  }
  @media #{$extra-small} {
    .card__container {
      margin: 0 5%;
    }
    .card__item-img {
      min-width: -webkit-fill-available;
    }
  }
</style>
