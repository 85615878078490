<template>
  <slick
    ref="carousel"
    :options="slickOptions"
    class="listing-slider mfp-gallery-container margin-bottom-0"
  >
    <!-- style="height: calc(100vw * 0.28125)" -->
    <template v-for="(item, index) in bannerItems">
      <div :key="index">
        <v-row
          :id="item.id"
          v-if="item.id == 'first-banner'"
          :style="`background: url(${item.route}) no-repeat center/100% 100%;`"
          class="banner banner--one"
        >
          <v-row class="content">
            <div class="col-12 col-sm-10 col-md-8 col-lg-7 content-right">
              <h6 class="content__title" data-aos="fade-down" data-aos-duration="1000">
                {{ item.title }}
              </h6>
              <h6
                class="content__text"
                v-html="item.subtitle"
                data-aos="fade-up"
                data-aos-duration="1000"
              ></h6>
            </div>
          </v-row>
          <v-row class="cta">
            <div
              class="border-animation"
              @click="$emit('showModal', true)"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button class="cta__btn border-animation__inner" type="button">
                {{ item.btnText }}
              </button>
            </div>
          </v-row>
        </v-row>
        <v-row
          :id="item.id"
          v-if="item.id == 'second-banner'"
          :key="index"
          class="banner banner--two"
          :style="`background: url(${item.route}) no-repeat center/100% 100%;`"
        >
          <v-row class="content">
            <div class="col-12 col-sm-6 content-left">
              <h6
                class="content__title"
                v-html="item.title"
                data-aos="fade-right"
                data-aos-duration="1000"
              ></h6>
            </div>
            <div class="col-12 col-sm-6 content-right">
              <h6
                class="content__text"
                v-html="item.subtitle"
                data-aos="fade-left"
                data-aos-duration="1000"
              ></h6>
            </div>
          </v-row>
          <v-row class="cta">
            <div class="border-animation" data-aos="fade-up" data-aos-duration="1000">
              <button
                class="cta__btn border-animation__inner"
                type="button"
                @click="$router.push('/franquicias')"
              >
                {{ item.btnText }}
              </button>
            </div>
          </v-row>
        </v-row>
        <!-- <v-row :id="item.id" v-if="item.id == 'third-banner'">
          <video-background
            :src="'/wiki-background.webm'"
            :poster="'/Snapshot_3.png'"
            :autoplay="true"
            :sources="[
              {
                src: '/wiki-background-2.mp4',
                res: 800,
                autoplay: true,
                poster: '/vlcsnap-00003.png',
              },
            ]"
            style="max-height: 1080px; height: 60vh"
            overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
          >
          </video-background>
        </v-row> -->
      </div>
    </template>
  </slick>
</template>

<script>
  import Slick from 'vue-slick'

  // import { Swiper, SwiperSlide } from 'swiper/vue'
  // import 'swiper/swiper-bundle.css'

  export default {
    name: 'Header1',
    components: {
      Slick,
      // Swiper,
      // SwiperSlide,
    },
    props: { bannerItems: Array },
    data() {
      return {
        show: true,
      }
    },
    watch: {
      items(newValue, oldValue) {
        this.reInit()
      },
    },
    methods: {
      reInit() {
        this.$refs.carousel.destroy()
        this.$nextTick(() => {
          this.$refs.carousel.create()
          this.$refs.carousel.goTo(0, true)
          this.$refs.carousel.setOption(true)
        })
      },
      sortingBanners() {
        this.items = this.bannerItems.sort(function () {
          return Math.random() - 0.5
        })
      },
    },
    created() {
      this.sortingBanners()
    },
    computed: {
      slickOptions() {
        return {
          dots: false,
          infinite: true,
          autoplay: false,
          autoplaySpeed: 10000,
          arrows: true,
          speed: 1000,
          fade: true,
          cssEase: 'linear',
          centerMode: true,
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 6,
          responsive: [
            {
              breakpoint: 1367,
              settings: {
                arrows: false,
                centerPadding: '0',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                arrows: false,
                centerPadding: '0',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 767,
              settings: {
                arrows: false,
                centerPadding: '0',
                slidesToShow: 1,
              },
            },
          ],
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  $laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
  $desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $large-mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
  $small-mobile: 'only screen and (min-width: 421px) and(max-width: 575px)';
  $extra-small-mobile: 'only screen and (max-width: 420px)';

  @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500;700&display=swap');
  .listing-slider .slick-slide .item,
  .slick-slider,
  .banner {
    height: calc(100vw * 0.36458);
  }

  .banner {
    // font-family: 'Maven Pro', sans-serif !important;
    // font-family: ;
    margin: 0;
    flex-direction: column;
    .row {
      margin: 0;
    }
    .row + .col {
      padding: 0;
    }
    .content {
      margin-top: 75px;
      padding: 0;
      height: 70%;
      width: 100%;
      // font-family: 'Maven Pro', sans-serif;
      // font-weight: 700 !important;
    }
    .content__title {
      font-size: 2rem;
    }
    .content__text {
      font-family: 'Maven Pro', sans-serif;
      font-size: 1.5rem;
      // font-weight: 500 !important;
    }
    .cta {
      margin: 5%;
      font-family: 'Maven Pro', sans-serif;
      justify-content: flex-end;
      align-items: center;
      border-radius: 0.25rem;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
    .cta__btn {
      font-size: 1rem;
    }
  }
  #fisrt-banner,
  .banner--one {
    color: #fff;
    .content {
      justify-content: flex-end;
      align-items: center;
      color: #fff;
    }
    .content-right {
      text-align-last: right;
      color: inherit;
      margin-right: 5%;
      padding: 0;
    }
    .content__title {
      margin-bottom: 1rem;
    }
    .content__title,
    .content__text {
      text-align: center;
      color: inherit;
    }
  }
  #second-banner,
  .banner--two {
    // font-family: 'Maven Pro', sans-serif !important;
    .content {
      height: 70%;
      // .content__title {
      // }
    }
    .content-left {
      padding: 5% 2% 5% 5%;
      text-align: left;
    }
    .content-right {
      padding: 5% 5% 5% 2%;
      align-self: center;
      text-align: right;
    }
  }

  .border-animation {
    --border-width: 5px;
    --animation-speed: 0.5s;
    --color: #fff;
    color: var(--color);
    position: relative;
    display: inline-block;
    font-size: 1rem;
    line-height: 1em;
    transform: scale(1, 0.8);
    border: var(--border-width) solid transparent;
    border-radius: 0.25rem;

    .border-animation__inner {
      position: relative;
      display: inline-block;
      width: 100%;
      // font-family: 'Six Caps', sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      cursor: pointer;
      padding: 0.2em;
      background: rgba(50, 50, 50, 0.5);
      background: #333;
      z-index: 1;
      border: solid var(--border-width) transparent;
      border-radius: 0.25rem;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: var(--color);
    }

    &:focus:before,
    &:hover:before {
      animation: beforeBorders var(--animation-speed) forwards ease-in-out;
    }

    &:focus:after,
    &:hover:after {
      animation: afterBorders var(--animation-speed) forwards ease-in-out;
    }

    &:focus,
    &:hover {
      animation: borderColors var(--animation-speed) steps(1) forwards;
      outline: none;

      .border-animation__inner {
        animation: background calc(var(--animation-speed) / 5 * 3) forwards ease-in-out;
        animation-delay: calc(var(--animation-speed) / 5 * 2);
      }
    }
  }

  @keyframes beforeBorders {
    0% {
      top: calc(var(--border-width) * -1);
      left: 50%;
      bottom: auto;
      right: auto;
      width: 0;
      height: var(--border-width);
    }
    33% {
      top: calc(var(--border-width) * -1);
      left: calc(var(--border-width) * -1);
      bottom: auto;
      right: auto;
      width: calc(var(--border-width) + 50%);
      height: var(--border-width);
    }
    66% {
      top: calc(var(--border-width) * -1);
      left: calc(var(--border-width) * -1);
      bottom: auto;
      right: auto;
      width: var(--border-width);
      height: calc((var(--border-width) * 2) + 100%);
    }
    100% {
      top: auto;
      left: calc(var(--border-width) * -1);
      bottom: calc(var(--border-width) * -1);
      right: auto;
      width: calc(var(--border-width) + 50%);
      height: calc((var(--border-width) * 2) + 100%);
    }
  }

  @keyframes afterBorders {
    0% {
      top: calc(var(--border-width) * -1);
      left: auto;
      bottom: auto;
      right: 50%;
      width: 0;
      height: var(--border-width);
    }
    33% {
      top: calc(var(--border-width) * -1);
      left: auto;
      bottom: auto;
      right: calc(var(--border-width) * -1);
      width: calc(var(--border-width) + 50%);
      height: var(--border-width);
    }
    66% {
      top: calc(var(--border-width) * -1);
      left: auto;
      bottom: auto;
      right: calc(var(--border-width) * -1);
      width: var(--border-width);
      height: calc((var(--border-width) * 2) + 100%);
    }
    100% {
      top: auto;
      left: auto;
      bottom: calc(var(--border-width) * -1);
      right: calc(var(--border-width) * -1);
      width: calc(var(--border-width) + 50%);
      height: calc((var(--border-width) * 2) + 100%);
    }
  }

  @keyframes borderColors {
    0% {
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    33% {
      border-top-color: var(--color);
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    66% {
      border-top-color: var(--color);
      border-right-color: var(--color);
      border-bottom-color: transparent;
      border-left-color: var(--color);
    }
    100% {
      border-top-color: var(--color);
      border-right-color: var(--color);
      border-bottom-color: var(--color);
      border-left-color: var(--color);
    }
  }

  @keyframes background {
    to {
      background: #555;
      text-shadow: 0 0.1em 0.1em #111;
    }
  }

  @media #{$desktop-device} {
    #first-banner,
    .banner--one {
      margin: 0;
      padding: 5%;
      .content__title {
        font-size: 1.5rem;
      }
      .content__text {
        font-size: 1.2rem;
      }
      .cta__btn {
        font-size: 0.8rem;
      }
    }
    #second-banner,
    .banner--two {
      .content__title {
        font-size: 1.5rem;
      }
      .content__text {
        font-size: 1.2rem;
      }
      .cta__btn {
        font-size: 0.8rem;
      }
    }
  }
  @media #{$tablet-device} {
    #first-banner,
    .banner--one {
      margin: 0;
      padding: 5%;
      .content__title {
        font-size: 1.3rem;
      }
      .content__text {
        font-size: 1.1rem;
      }
      .cta__btn {
        font-size: 0.8rem;
      }
    }
    #second-banner,
    .banner--two {
      .content__title {
        font-size: 1.3rem;
      }
      .content__text {
        font-size: 1.1rem;
      }
      .cta__btn {
        font-size: 0.8rem;
      }
    }
  }
  @media #{$large-mobile} {
    .banner {
      .content {
        margin-top: 0px;
        flex-wrap: nowrap;
      }
    }
    #first-banner,
    .banner--one {
      .content__title {
        font-size: 1.3rem;
      }
      .content__text {
        font-size: 1.1rem;
      }
      .cta__btn {
        font-size: 0.6rem;
      }
    }
    #second-banner,
    .banner--two {
      .content__title {
        font-size: 1.3rem;
      }
      .content__text {
        font-size: 1.1rem;
      }
      .cta__btn {
        font-size: 0.6rem;
      }
    }
  }
  @media #{$small-mobile} {
    .listing-slider .slick-slide .item,
    .slick-slider,
    .banner {
      height: calc(100vw * 1.2439);
    }
    .banner {
      .content {
        margin-top: 0px;
      }
      .cta {
        right: 30px;
        bottom: 30px;
      }
    }
    #fisrt-banner,
    .banner--one {
      color: #fff;
      .content {
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        padding: 5%;
        background: rgb(0 0 0 / 40%);
      }
      .content-right {
        text-align-last: right;
        color: inherit;
        // margin-right: 5%;
        padding: 5%;
      }
      .content__title {
        text-align: end;
        color: inherit;
        font-size: 2rem;
        margin-bottom: 2rem;
      }
      .content__text {
        text-align: end;
        color: inherit;
        font-size: 1.5rem;
      }
    }
    #second-banner,
    .banner--two {
      text-align: left;
      .content {
        padding: 5%;
        display: flex;
        align-items: flex-end;
      }
      .content__title {
        font-size: 1.8rem;
      }
      .content__text {
        font-size: 1.5rem;
      }
      .content-left {
        padding: 5%;
        align-self: end;
      }
      .content-right {
        padding: 5%;
        text-align: end;
        align-self: start;
      }
    }
  }
  @media #{$extra-small-mobile} {
    .listing-slider .slick-slide .item,
    .slick-slider,
    .banner {
      height: calc(100vw * 1.2439);
    }
    .banner {
      .content {
        margin-top: 0px;
      }
      .cta {
        right: 15px;
        bottom: 15px;
      }
    }
    #fisrt-banner,
    .banner--one {
      color: #fff;
      .content {
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        padding: 5%;
        background: rgb(0 0 0 / 40%);
      }
      .content-right {
        text-align-last: right;
        color: inherit;
        // margin-right: 5%;
        padding: 5%;
      }
      .content__title {
        text-align: end;
        color: inherit;
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
      .content__text {
        text-align: end;
        color: inherit;
        font-size: 1.2rem;
      }
    }
    #second-banner,
    .banner--two {
      text-align: left;
      .content {
        padding: 0;
        display: flex;
        align-items: flex-end;
      }
      .content__title {
        font-size: 1.5rem;
      }
      .content__text {
        font-size: 1.2rem;
      }
      .content-left {
        padding: 5%;
        align-self: end;
      }
      .content-right {
        padding: 5%;
        text-align: end;
        align-self: start;
      }
    }
  }
</style>
