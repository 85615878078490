<template>
  <v-card-text data-app class="content__country-flag p-0"
    ><v-row class="country__container m-0" v-if="countries && countries.length > 0"
      ><v-tooltip top v-for="country in countries" :key="country.id">
        <template v-slot:activator="{ on, attrs }">
          <img
            class="country__flag"
            v-bind="attrs"
            v-on="on"
            :src="country.flag"
          /> </template
        ><span>{{ country.name }}</span></v-tooltip
      ></v-row
    >
    <v-row class="country__container m-0" v-else-if="countries && countries.length == 0">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon style="font-size: xx-large" v-bind="attrs" v-on="on">
            mdi-web
          </v-icon> </template
        ><span>Global</span></v-tooltip
      >
    </v-row>
  </v-card-text>
</template>
<script>
  export default {
    name: 'CountriesFlags',
    props: {
      countries: {
        default: [],
        type: Array,
        required: true,
      },
    },
  }
</script>
<style lang="scss">
  .country__flag {
    margin: 0 5px;
    width: 35px;
    height: 25px;
  }
</style>
