<template>
  <div style="cursor: pointer">
    <slick ref="infographic-carousel" id="infographic-carousel" :options="slickOptions">
      <template v-for="(item, index) in infographics">
        <div :key="index" @click="openGallery(index)">
          <v-icon
            class="round-pill d-block bg-primary text-center"
            style="
              position: absolute;
              top: 0px;
              right: 0px;
              color: white;
              width: 30px;
              height: 30px;
              z-index: 1;
              opacity: 1;
              text-align: center;
              vertical-align: -webkit-baseline-middle;
              vertical-align: sub;
            "
            >mdi-cursor-default-click</v-icon
          >
          <v-img contain :aspect-ratio="item.aspectRatio" :src="item.thumb"></v-img>
        </div>
      </template>
    </slick>
    <keep-alive>
      <light-box
        ref="lightbox"
        :media="infographics"
        :nThumbs="5"
        :show-caption="false"
        :show-light-box="false"
        :showFooterCount="false"
        :disableZoom="false"
      >
        <div slot="close">
          <v-btn
            style="
              position: absolute;
              top: 0px;
              right: -80px;
              color: #fff;
              background: transparent;
              font-size: 2rem;
              box-shadow: none;
            "
          >
            <v-icon style="font-size: 2rem">mdi-close</v-icon>
          </v-btn>
        </div>
      </light-box>
    </keep-alive>
  </div>
</template>
<script>
  import Slick from 'vue-slick'
  import LightBox from 'vue-image-lightbox'
  export default {
    name: 'InfographicGallery',

    data() {
      return {
        infographics: [
          {
            thumb:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/824df585401352551d74b871eb5810e5.jpg',
            src:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/824df585401352551d74b871eb5810e5.jpg',
            aspectRatio: 3 / 4,
          },
          {
            thumb:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/26490c1b61c23e828f19c0baa1081b87.jpg',
            src:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/26490c1b61c23e828f19c0baa1081b87.jpg',
            aspectRatio: 3 / 4,
          },
          {
            thumb:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/88c3db1340d3249de4df82a70cabc0d3.jpg',
            src:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/88c3db1340d3249de4df82a70cabc0d3.jpg',
            aspectRatio: 3 / 4,
          },
          {
            thumb:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/1b8da893f69ee3614e3d2ca697ffbb0e.jpg',
            src:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/1b8da893f69ee3614e3d2ca697ffbb0e.jpg',
            aspectRatio: 3 / 4,
          },
          {
            thumb:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/ec0148c069bf83d3e40d53b60c38dc8f.jpg',
            src:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/ec0148c069bf83d3e40d53b60c38dc8f.jpg',
            aspectRatio: 3 / 4,
          },
        ],
      }
    },
    methods: {
      openGallery(index) {
        this.$refs.lightbox.showImage(index)
      },
    },
    components: { Slick, LightBox },

    computed: {
      slickOptions() {
        return {
          accessibility: true,
          adaptiveHeight: true,
          autoplay: true,
          autoplaySpeed: 30000,
          arrows: false,
          dots: true,
          infinite: true,
          speed: 1000,
          fade: true,
          cssEase: 'linear',
          centerMode: true,
          centerPadding: '0',
          pauseOnFocus: true,
          pauseOnHover: true,
          pauseOnDotsHover: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          // responsive: [
          //   {
          //     breakpoint: 1367,
          //     settings: {
          //       arrows: false,
          //       centerPadding: '0',
          //       slidesToShow: 1,
          //     },
          //   },
          //   {
          //     breakpoint: 1025,
          //     settings: {
          //       arrows: false,
          //       centerPadding: '0',
          //       slidesToShow: 1,
          //     },
          //   },
          //   {
          //     breakpoint: 767,
          //     settings: {
          //       arrows: false,
          //       centerPadding: '0',
          //       slidesToShow: 1,
          //     },
          //   },
          // ],
        }
      },
    },
  }
</script>
<style lang="scss">
  #infographic-carousel {
    .slick-dotted.slick-slider {
      margin-bottom: 0px;
    }
  }
  .vue-lb-container {
    // align-items: center;
    // background-color: rgba(0,0,0,.8);
    // box-sizing: border-box;
    // display: flex;
    height: 100%;
    padding: 50px 100px;
    // justify-content: center;
    // left: 0;
    // padding: 10px;
    // position: fixed;
    // top: 0;
    // width: 100%;
    z-index: 99999;
    // -webkit-align-items: center;
    // -moz-box-sizing: border-box;
    // -webkit-justify-content: center;
    // -ms-flex-align: center;
    // -webkit-box-align: center;
    // -ms-flex-pack: center;
    // -webkit-box-pack: center;
  }
</style>
