<template>
  <div class="card-lift--hover">
    <router-link :to="'/publicaciones/' + item.slug">
      <v-card :loading="loading" class="mx-auto">
        <v-img
          v-if="item.cover && item.cover.route"
          :aspect-ratio="1 / 1.3"
          :src="item.cover.route"
          style="background-size: contain"
        ></v-img>
        <v-card-text class="pt-6" style="position: relative">
          <h6 style="font-size: 0.85rem; height: 60px" class="my-auto">
            {{ item.title.slice(0, 80) }}
          </h6>
          <p class="text-muted" style="text-transform: capitalize">
            {{ item.dateText }}
          </p>
        </v-card-text>

        <!-- <v-card-text style="height: 120px">
          {{ item.shortdesc }}
        </v-card-text> -->

        <!-- <v-divider class="mx-4 my-1"></v-divider>-->
        <!-- <div class="card__button-container col-12 col-sm-auto p-0">
          <v-card-actions>
            <v-btn
              color="deep-purple lighten-2"
              text
              @click.prevent="$router.push('publicaciones/' + item.slug)"
            >
              Ver más
            </v-btn>
          </v-card-actions>
        </div> -->
      </v-card>
    </router-link>
  </div>
</template>
<script>
  export default {
    name: 'PublicationsCardV',
    props: {
      item: {
        type: Object,
        required: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
  .text-muted {
    font-size: 0.75rem;
    margin-right: 10px;
    display: inline-block;
    text-transform: capitalize;
  }
  .card__button-container {
    width: inherit;
    display: flex;
    justify-content: flex-end;
  }
  .card-lift--hover:hover {
    transform: scale(1.03);
    transition: all 0.15s ease;
  }
</style>
