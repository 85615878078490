<template>
  <div class="container">
    <v-row no-gutters>
      <div class="col-12 mb-3" v-if="title && title != ''">
        <h3 class="text-center m-0">{{ title }}</h3>
      </div>
    </v-row>
    <v-row v-if="companies.length == 0">
      <div class="col-lg-12" v-for="index in 2" :key="index">
        <v-skeleton-loader
          :boilerplate="boilerplateActive"
          class="mx-auto"
          style="height: 140px"
          type="card"
        ></v-skeleton-loader>
      </div>
    </v-row>
    <div v-else>
      <v-row
        no-gutters
        class=""
        style="flex-direction: row"
        v-for="company in companies.slice(0, 2)"
        :key="company.id"
      >
        <div
          class="col-12 col-sm-6"
          style="
            padding: 10px;
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <router-link :to="'/empresa/' + company.link">
            <img
              v-if="!company.logo"
              src="/static/images/generico.png"
              style="max-height: 100%; max-width: 100%"
              :alt="company.name"
            />
            <img
              v-else
              :src="company.logo.route"
              :alt="company.name"
              style="max-height: 100%; max-width: 100%"
            />
          </router-link>
        </div>
        <div
          class="col-12 col-sm-6"
          style="
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
        >
          <router-link :to="'/empresa/' + company.link">
            <h5 class="mb-1">{{ company.name }}</h5>
          </router-link>
          <span
            style="margin: 3px 0"
            class="badge badge-pill badge-primary text-uppercase badge-cat"
            v-for="(category, index) in company.categories.slice(0, 2)"
            :key="index"
            >{{ category.name }}</span
          >
        </div>
        <router-link :to="'/empresa/' + company.link"
          ><span
            title="Proveedor destacado"
            class="round-pill like-banner d-block bg-primary action-span"
            ><i class="fa fa-star-o"></i></span
        ></router-link>
      </v-row>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['country', 'type', 'outstanding', 'title'],
    data() {
      return {
        intervalFunction: '',
        companies: [],
        boilerplateActive: false,
      }
    },
    watch: {
      country(newValue, oldValue) {
        this.getCompanies()
      },
      type(newValue, oldValue) {
        this.getCompanies()
        this.adsTimer()
      },
    },
    created() {
      this.getCompanies()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      adsTimer() {
        this.intervalFunction = setInterval(this.getCompanies, 60000)
      },
      async getCompanies() {
        let countryStr = ''
        let limitStr = '&limit=2'
        let outstangingStr = ''
        if (this.country) {
          countryStr = '&country_id=' + this.country.id
        }
        if (this.outstanding && this.outstanding == 1) {
          outstangingStr = '&outstanding=1'
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-list?random=1&active=1&type=' +
              this.type +
              countryStr +
              outstangingStr +
              limitStr
          )
          .then((response) => {
            this.companies = response.data
            if (this.companies.length == 0) {
              this.boilerplateActive = true
            }
          })
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
  }
</script>
<style lang="scss" scoped>
  $xlmax-device: 'only screen and (min-width: 1500px)';
  $laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
  $desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $large-mobile: 'only screen and (max-width: 767px)';
  $small-mobile: 'only screen and (max-width: 575px)';
  $extra-small-mobile: 'only screen and (max-width: 479px)';

  .round-pill {
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 1.3rem;
    i {
      padding-top: 2px;
      line-height: 1.3rem;
    }
  }
  .action-span {
    transform: rotate(360deg);
    transition-property: rotation;
    transition-timing-function: linear;
    transition-duration: 3s;
    animation: rotation 1s infinite linear;
  }
  .listing-item {
    display: flex;
    justify-content: space-evenly;
    height: auto;
    background-color: transparent;
    //z-index: 1;
  }
  .listing-item-content {
    height: inherit;
  }
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .like-banner {
    position: absolute;
    left: 5px;
    top: 5px;
    line-height: 1.5;
    z-index: 100;
  }
  @media #{$desktop-device} {
    .custom-buttom {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  @media #{$tablet-device} {
    .custom-buttom {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }

  @media #{$large-mobile} {
    .message {
      font-size: 16px;
      width: 90%;
      margin: auto;
    }
    .custom-buttom {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }

  @media #{$small-mobile} {
    .custom-buttom {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
</style>
