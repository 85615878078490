<template>
  <div class="container" data-app>
    <div class="col-12">
      <h3 class="text-center"></h3>
    </div>
    <div class="border-0">
      <div class="p-0">
        <div class="row align-items-center">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
              <v-container style="height: 100%">
                <v-card style="width: 100%; height: inherit">
                  <v-card-title
                    class="white--text mt-8"
                    style="background-color: #003a56"
                  >
                    <v-avatar size="40" style="background-color: #fff">
                      <v-icon style="color: #003a56">mdi-account-tie</v-icon>
                    </v-avatar>
                    <a href="https://cogfranquicias.com/" target="_blank" rel="noopener"
                      ><h5 class="ml-3" style="color: #fff">Consultoría</h5></a
                    >
                  </v-card-title>

                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="messages in itemsConsult"
                      :key="messages.time"
                      :color="messages.color"
                      hide-dot
                    >
                      <div>
                        <div class="font-weight-normal">
                          <a>
                            {{ messages.from }}
                          </a>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card>
              </v-container>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
              <v-container style="height: 100%">
                <v-card style="width: 100%; height: inherit">
                  <v-card-title
                    class="white--text mt-8"
                    style="background-color: #003a56"
                  >
                    <v-avatar size="40" style="background-color: #fff">
                      <v-icon style="color: #003a56">mdi-code-braces</v-icon>
                    </v-avatar>
                    <a href="https://cogfranquicias.com/" target="_blank" rel="noopener"
                      ><h5 class="ml-3" style="color: #fff">Tecnología</h5></a
                    >
                  </v-card-title>

                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="messages in itemsIT"
                      :key="messages.time"
                      :color="messages.color"
                      hide-dot
                    >
                      <div>
                        <div class="font-weight-normal">
                          <a>
                            {{ messages.from }}
                          </a>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card>
              </v-container>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
              <v-container style="height: 100%">
                <v-card style="width: 100%; height: inherit">
                  <v-card-title
                    class="white--text mt-8"
                    style="background-color: #003a56"
                  >
                    <v-avatar size="40" style="background-color: #fff">
                      <v-icon style="color: #003a56">mdi-chart-line</v-icon>
                    </v-avatar>
                    <a href="https://cogfranquicias.com/" target="_blank" rel="noopener"
                      ><h5 class="ml-3" style="color: #fff">Estrategias</h5></a
                    >
                  </v-card-title>

                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="messages in itemsStrategy"
                      :key="messages.time"
                      :color="messages.color"
                      hide-dot
                    >
                      <div>
                        <div class="font-weight-normal">
                          <a>
                            {{ messages.from }}
                          </a>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card>
              </v-container>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
              <v-container style="height: 100%">
                <v-card style="width: 100%; height: inherit">
                  <v-card-title
                    class="white--text mt-8"
                    style="background-color: #003a56"
                  >
                    <v-avatar size="40" style="background-color: #fff">
                      <v-icon style="color: #003a56">mdi-desktop-mac-dashboard</v-icon>
                    </v-avatar>
                    <a href="https://cogfranquicias.com/" target="_blank" rel="noopener"
                      ><h5 class="ml-3" style="color: #fff">Diseño y espacios</h5></a
                    >
                  </v-card-title>

                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="messages in itemsDesign"
                      :key="messages.id"
                      :color="messages.color"
                      hide-dot
                    >
                      <div>
                        <div class="font-weight-normal">
                          <a>
                            {{ messages.from }}
                          </a>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card>
              </v-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'desc', 'bg-image-url'],
    data: () => ({
      itemsConsult: [
        {
          from: 'Consultoría de Franquicia',
          color: '#003a56',
        },
        {
          from: 'Expansión de Franquicias',
          color: '#003a56',
        },
        {
          from: 'Consultoría Jurídica',
          color: '#003a56',
        },
        {
          from: 'Consultoría Gerencial',
          color: '#003a56',
        },
      ],
      itemsIT: [
        {
          from: 'Franchising Cloud',
          color: '#003a56',
        },
        {
          from: 'Desarrollo de Tecnología',
          color: '#003a56',
        },
        {
          from: 'IT',
          color: '#003a56',
        },
        {
          from: 'E-Commerce',
          color: '#003a56',
        },
      ],
      itemsStrategy: [
        {
          from: 'Servicios Ad Hoc',
          color: '#003a56',
        },
        {
          from: 'Conferencias y Charlas',
          color: '#003a56',
        },
        {
          from: 'Inteligencia de Negocios',
          color: '#003a56',
        },
        {
          from: 'Mercadeo Digital',
          color: '#003a56',
        },
      ],
      itemsDesign: [
        {
          from: 'Diseño Grafico',
          color: '#003a56',
        },
        {
          from: 'Diseño Arquitectónico',
          color: '#003a56',
        },
        {
          from: 'Procura Inmobiliaria',
          color: '#003a56',
        },
      ],
    }),
  }
</script>
<style lang="scss">
  .v-timeline-item {
    display: flex;
    padding-bottom: 24px;
    justify-content: center;
  }
  .v-timeline-item__dot--small .v-timeline-item__inner-dot {
    height: 10px;
    margin: 0px;
    width: 10px;
  }
  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 10px;
  }
  .v-timeline--dense .v-timeline-item__body {
    max-width: 95%;
    width: 95%;
  }
  .v-timeline-item__divider {
    position: relative;
    min-width: 30px !important;
    width: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-timeline {
    padding-top: 20px;
    position: relative;
    height: 80%;
  }
  .v-card__title {
    height: 20%;
  }
</style>
