<template>
  <v-row>
    <carousel-3d
      :controls-visible="true"
      :autoplay="true"
      :autoplayTimeout="10000"
      :autoplayHoverPause="true"
      :width="400"
      :space="350"
      :height="250"
      :perspective="35"
      :scale="200"
      :animation-speed="1000"
      :display="5"
      :clickable="true"
      style="margin: 40px 10%"
    >
      <slide v-for="(item, index) in videos" :index="item.index" :key="index">
        <iframe
          :src="item.src"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="item.title"
        ></iframe
      ></slide>
    </carousel-3d>
  </v-row>
</template>
<script>
  export default {
    name: 'HomeVideoCarousel',
    props: {
      videos: Array,
    },
  }
</script>
