<template>
  <v-app id="inspire">
    <v-card class="w-100">
      <h3 class="card__title">Glosario de Términos</h3>
      <v-row class="m-0" justify="space-between">
        <div class="col-12 col-sm-5" style="">
          <v-treeview
            :active.sync="active"
            :items="items"
            :open.sync="open"
            activatable
            color="#009ada"
            open-on-click
            shaped
            transition
            hoverable
            dense
            return-object
            @update:active="getSelectedWord($event)"
            @update:open="handleOpen($event)"
          >
          </v-treeview>
          <div
            class="col-12 p-0 button-glosary"
            style="display: flex; justify-content: flex-end"
          >
            <!-- <v-btn
              color=" lighten-2"
              text
              @click.prevent="$router.push('/wikifranquicias')"
            >
              <v-icon style="margin-right: 5px"> mdi-playlist-plus </v-icon>
              Ver todo
            </v-btn> -->
          </div>
        </div>

        <v-divider vertical></v-divider>

        <!-- style="overflow-y: auto; height: 100%" -->
        <div class="col-12 col-sm-7 d-flex align-items-center text-center">
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!selected.id"
              class="text-h6 grey--text text--lighten-1 font-weight-light"
              style="align-self: center"
            >
              Seleciona un Término del Glosario
            </div>
            <v-card v-else :key="selected.id" class="d-flex mx-auto" flat>
              <v-card-text
                class="d-flex align-items-center text-center"
                style="margin-bottom: 30px"
              >
                <div>
                  <h3 class="content__title mb-2">{{ selected.title }}</h3>
                  <p class="content__text mb-2" v-html="selected.shortdesc"></p>
                </div>
              </v-card-text>
              <!-- <div
                class="col-12 p-0 button-glosary"
                style="display: flex; justify-content: flex-end; bottom: 0px"
              >
                <v-btn
                  v-if="selected && selected.id"
                  color=" lighten-2"
                  text
                  @click.prevent="$router.push('/')"
                >
                  <v-icon style=""> mdi-magnify-plus </v-icon>
                </v-btn>
              </div> -->
            </v-card>
          </v-scroll-y-transition>
        </div>
      </v-row>
    </v-card>
  </v-app>
</template>

<script>
  export default {
    name: 'GlosaryTree',
    data: () => ({
      active: [],
      open: [],
      selected: {},
      items: [],
    }),
    filters: {},
    created() {
      this.getWords()
    },
    computed: {},
    methods: {
      async getWords() {
        this.open = []
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'category-management/categories-tree')
          .then((response) => {
            this.items = response.data
            this.items.forEach((category) => {
              category.children = category.children.slice(0, 3)
            })
            this.open = [this.items[0]]
            this.selected = [this.items[0].children[0]]
            this.active = [this.items[0].children[0]]
          })
      },
      async getSelectedWord(wordId) {
        this.selected = wordId[0]
      },
      handleOpen(open) {
        const array = Object.values(open)
        if (open.length > 0) {
          this.open = [array[array.length - 1]]
        }
      },
    },
  }
</script>
<style lang="scss">
  #inspire {
    font-family: inherit;
    height: 100%;
    > .v-application--wrap {
      min-height: initial;
      max-height: 100%;
      flex-direction: row;
    }

    p {
      font-size: 1.1rem !important;
      // font-weight: 300;
      line-height: 1.3 !important;
    }
    // p-2 mb-0 ml-4
    .card__title {
      margin: 0;
      padding: 10px 10px 10px 50px;
      background: linear-gradient(to right, #ffc60b, white) 0% / 120% no-repeat;
    }
    .content__title {
      font-size: 2rem;
    }
    .content__text {
      font-size: 1.5rem !important;
      color: rgb(0, 154, 218) !important;
      > p,
      > p span {
        background-color: white !important;
        color: rgb(0, 154, 218) !important;
      }
    }
    .button-glosary {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      width: 95%;
      bottom: 10px;
    }
  }
</style>
