import { render, staticRenderFns } from "./PublicationsCardS.vue?vue&type=template&id=291060ba&scoped=true&"
import script from "./PublicationsCardS.vue?vue&type=script&lang=js&"
export * from "./PublicationsCardS.vue?vue&type=script&lang=js&"
import style0 from "./PublicationsCardS.vue?vue&type=style&index=0&id=291060ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "291060ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCol,VImg,VRow})
