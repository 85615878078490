<template data-app>
  <div class="container pt-0">
    <div class="nav-wrapper mb-2 p-0">
      <ul
        class="nav nav-pills nav-fill flex-column flex-md-row"
        id="tabs-icons-text"
        role="tablist"
      >
        <li class="nav-item">
          <a
            class="nav-link mb-sm-3 active mb-md-0"
            id="tabs-icons-text-1-tab"
            data-toggle="tab"
            href="#tabs-icons-text-1"
            role="tab"
            @click="selected = 1"
            aria-controls="tabs-icons-text-1"
            aria-selected="true"
            ><v-icon
              dark
              :color="selected === 1 ? '#fff' : '#003a56'"
              style="margin-right: 5px"
            >
              mdi-newspaper-variant-multiple-outline </v-icon
            >Noticias</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-sm-3 mb-md-0"
            id="tabs-icons-text-2-tab"
            data-toggle="tab"
            @click="selected = 2"
            href="#tabs-icons-text-2"
            role="tab"
            aria-controls="tabs-icons-text-2"
            aria-selected="false"
            ><v-icon
              dark
              :color="selected === 2 ? '#fff' : '#003a56'"
              style="margin-right: 5px"
            >
              mdi-sticker-text </v-icon
            >Blog</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-sm-3 mb-md-0 show"
            id="tabs-icons-text-3-tab"
            data-toggle="tab"
            href="#tabs-icons-text-3"
            role="tab"
            @click="selected = 3"
            aria-controls="tabs-icons-text-3"
            aria-selected="false"
            ><v-icon
              dark
              :color="selected === 3 ? '#fff' : '#003a56'"
              style="margin-right: 5px"
            >
              mdi-calendar-month </v-icon
            >Eventos</a
          >
        </li>
      </ul>
    </div>
    <div class="card shadow">
      <div class="card-body">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade active show"
            id="tabs-icons-text-1"
            role="tabpanel"
            aria-labelledby="tabs-icons-text-1-tab"
          >
            <div class="row" style="margin: 0px">
              <div
                class="col-md-4 col-sm-6 col-xs-12 mx-auto"
                style="padding: 5px"
                v-for="notice in news.slice(0, 6)"
                :key="notice.id"
              >
                <router-link :to="'/publicaciones/' + notice.slug">
                  <v-hover style="padding: 0px" v-slot="{ hover }">
                    <v-card :elevation="5" color="grey lighten-4 news-box">
                      <v-img
                        :aspect-ratio="16 / 9"
                        v-if="notice.cover && notice.cover.route"
                        :src="notice.cover.route"
                      >
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out v-card--reveal text-white"
                            style="height: 100%"
                          >
                            {{ notice.shortdesc }}
                          </div>
                        </v-expand-transition>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative; height: 100%">
                        <p class="text-muted" style="text-transform: capitalize">
                          {{ notice.dateText }}
                        </p>
                        <h6 style="font-size: 15px; height: 65px" class="my-auto">
                          {{ notice.title.slice(0, 80) }}
                        </h6>
                        <v-card-text
                          data-app
                          class="content__country-flag p-0"
                          style="position: relative; z-index: 10"
                          ><v-row
                            class="country__container m-0"
                            style="justify-content: flex-end"
                            v-if="notice.countries && notice.countries.length > 0"
                            ><v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <img
                                  class="country__flag"
                                  v-bind="attrs"
                                  v-on="on"
                                  :src="notice.countries[0].flag"
                                /> </template
                              ><span>{{ notice.countries[0].name }}</span></v-tooltip
                            ></v-row
                          >
                          <v-row class="country__container m-0" v-else>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  style="font-size: xx-large"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-web
                                </v-icon> </template
                              ><span>Global</span></v-tooltip
                            >
                          </v-row>
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </router-link>
              </div>

              <div class="col-12" style="display: flex; justify-content: flex-end">
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click.prevent="$router.push('/noticias')"
                >
                  <v-icon style="margin-right: 5px">
                    mdi-newspaper-variant-multiple-outline
                  </v-icon>
                  Ver todas las noticias
                </v-btn>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="tabs-icons-text-2"
            role="tabpanel"
            aria-labelledby="tabs-icons-text-2-tab"
          >
            <v-row
              class="mx-0"
              v-if="blogsH.length > 0 || blogsV.length > 0 || blogsSquare.length > 0"
            >
              <div class="col-lg-4" style="padding-left: 0px; padding-bottom: 0px">
                <publications-card-v v-if="blogsV.length > 0" :item="blogsV[0]" />
              </div>
              <div class="col-lg-8" style="padding-right: 0px; padding-bottom: 0px">
                <publications-card-h :items="blogsH.slice(0, 3)" />
              </div>
              <div class="col-12" style="padding-left: 0px; padding-right: 0px">
                <v-row v-if="blogsSquare.length > 0">
                  <v-col
                    cols="12"
                    sm="6"
                    lg="4"
                    v-for="post in blogsSquare"
                    :key="post.id"
                  >
                    <publications-card-s :item="post" />
                  </v-col>
                </v-row>
              </div>
            </v-row>
            <div class="row" v-else>
              <div class="col-lg-4" v-for="index in 3" :key="index">
                <v-skeleton-loader
                  class="mx-auto"
                  style="height: 450px"
                  type="card"
                ></v-skeleton-loader>
              </div>
            </div>
            <div class="col-12" style="display: flex; justify-content: flex-end">
              <v-btn
                color="deep-purple lighten-2"
                text
                @click.prevent="$router.push('/blog')"
              >
                <v-icon style="margin-right: 5px"> mdi-blogger </v-icon>
                Ir al Blog
              </v-btn>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="tabs-icons-text-3"
            role="tabpanel"
            aria-labelledby="tabs-icons-text-3-tab"
          >
            <div class="row">
              <div
                class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
                v-for="event in events.slice(0, 3)"
                :key="event.id"
              >
                <router-link :to="'/publicaciones/' + event.slug">
                  <v-card :loading="loadingEvents" class="mx-auto">
                    <v-img
                      :aspect-ratio="1"
                      :src="event.cover.route"
                      style="background-size: contain"
                    ></v-img>

                    <h6 class="event-title">{{ event.title.slice(0, 80) }}</h6>

                    <v-card-text style="height: 120px">
                      <!--<v-row align="center" class="mx-0">
                      <v-rating
                        :value="4.5"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="14"
                      ></v-rating>event

                      <div class="grey--text ms-4">4.5 (413)</div>
                    </v-row>

                    <div class="my-4 text-subtitle-1">$ • Italian, Cafe</div>-->

                      {{ event.shortdesc }}
                    </v-card-text>

                    <v-divider class="mx-4 my-1"></v-divider>

                    <v-card-text style="height: 140px">
                      <countries-flags
                        style="margin: 5px 0 5px -5px"
                        :countries="event.countries"
                        v-if="event.address"
                      ></countries-flags>
                      <span v-if="event.dateRange > 0 && event.startAt"
                        ><strong>Inicia:</strong> {{ event.startAt }}</span
                      ><br v-if="event.dateRange > 0 && event.startAt" />
                      <span v-if="event.dateRange > 0 && event.expired_at"
                        ><strong>Culmina:</strong> {{ event.endAt }}</span
                      ><br v-if="event.dateRange > 0 && event.expired_at" />
                      <span v-if="event.dateRange == 0"
                        ><strong>Fecha:</strong>
                        {{ event.startAt + ' . Único día' }}</span
                      ><br v-if="event.dateRange == 0" />
                      <span v-if="event.modality"
                        ><strong>Modalidad:</strong> {{ event.modalityText }}</span
                      >
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click.prevent="$router.push('publicaciones/' + event.slug)"
                      >
                        Ver más
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </router-link>
              </div>
              <div class="col-12" style="display: flex; justify-content: flex-end">
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click.prevent="$router.push('/eventos')"
                >
                  <v-icon style="margin-right: 5px"> mdi-calendar-month </v-icon>
                  Ver todos los eventos
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PublicationsCardH from 'Components/globalFrontendComponents/PublicationsCardH'
  import PublicationsCardV from 'Components/globalFrontendComponents/PublicationsCardV'
  import PublicationsCardS from 'Components/globalFrontendComponents/PublicationsCardS'
  import CountriesFlags from 'Components/globalFrontendComponents/CountriesFlags'

  export default {
    data() {
      return {
        center: {
          lat: -34.397,
          lng: 150.644,
        },
        selected: 1,
        loadingEvents: false,
        loadingBlog: false,
        blogs: [],
        events: [],
        news: [],
        blogsH: [],
        blogsV: [],
        blogsSquare: [],
      }
    },
    components: {
      PublicationsCardH,
      PublicationsCardV,
      PublicationsCardS,
      CountriesFlags,
    },
    created() {
      this.getBlogsV()
      this.getBlogsH()
      this.getBlogsSquare()
      this.getEvents()
      this.getNews()
    },
    methods: {
      async getNews() {
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?active=1&order=date&by=desc&type=news'
          )
          .then((response) => {
            this.news = response.data.data
            this.news.map((newItem) => {
              newItem.dateText = new Date(newItem.date).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            })
          })
      },
      async getBlogsH() {
        this.loadingBlog = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?typecover=horizontal&active=1&limit=2&order=date&by=desc&type=blogs'
          )
          .then((response) => {
            this.blogsH = response.data
            this.blogsH.map((blog) => {
              blog.dateText = new Date(blog.created_at).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            })
            this.loadingBlog = false
          })
      },
      async getBlogsV() {
        this.loadingBlog = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?typecover=vertical&active=1&limit=1&order=date&by=desc&type=blogs'
          )
          .then((response) => {
            this.blogsV = response.data
            this.blogsV.map((blog) => {
              blog.dateText = new Date(blog.created_at).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            })
            this.loadingBlog = false
          })
      },
      async getBlogsSquare() {
        this.loadingBlog = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?typecover=square&active=1&limit=3&order=date&by=desc&type=blogs'
          )
          .then((response) => {
            this.blogsSquare = response.data
            this.blogsSquare.map((blog) => {
              blog.dateText = new Date(blog.created_at).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            })
            this.loadingBlog = false
          })
      },
      async getEvents() {
        this.loadingEvents = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?active=1&order=date&by=asc&type=events'
          )
          .then((response) => {
            this.events = response.data.data
            this.events.map((event) => {
              event.startAt = new Date(event.date).toLocaleDateString('es-VE', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              event.endAt = new Date(event.expired_at).toLocaleDateString('es-VE', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              event.dateRange = Math.ceil(
                Math.abs(new Date(event.date) - new Date(event.expired_at)) /
                  (1000 * 60 * 60 * 24)
              )
              switch (event.modality) {
                case 'site':
                  event.modalityText = 'Presencial'
                  break
                case 'remote':
                  event.modalityText = 'Virtual'
                  break
                case 'remote-site':
                  event.modalityText = 'Virtual - Presencial'
                  break
                default:
                  event.modalityText = 'N/A'
                  break
              }
            })
            this.loadingEvents = false
          })
      },
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
    },
  }
</script>

<style lang="scss" scoped>
  // Responsive Variables
  $xlmax-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
  $laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
  $desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $large-mobile: 'only screen and (max-width: 767px)';
  $small-mobile: 'only screen and (max-width: 575px)';
  $extra-small-mobile: 'only screen and (max-width: 479px)';

  @media #{$tablet-device} {
  }
  .active {
    background-color: #fff;
    color: #003a55;
  }
  .nav-pills .nav-link {
    font-size: 1.1rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 20%), 0 1px 3px rgb(0 0 0 / 15%);
  }
  .nav-pills .nav-link:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  .tab-content {
    padding: 0px;
  }
  .v-skeleton-loader__card {
    height: 100%;
  }
  .v-skeleton-loader__image {
    height: 90%;
  }
  .v-skeleton-loader__card-heading {
    height: 10%;
  }
  .v-card--reveal {
    align-items: center !important;
    bottom: 0 !important;
    justify-content: center !important;
    opacity: 0.85 !important;
    position: absolute !important;
    width: 100% !important;
    background-color: #003a56;
    padding: 10px;
    font-size: small;
  }

  .card-body {
    padding: 1.5rem;
  }
  .description {
    color: #000;
    height: 75px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3rem;
  }
  .news-box {
    .v-responsive {
      position: inherit;
      overflow: hidden;
      flex: 1 0 auto;
      max-width: 100%;
      display: block !important;
      :hover {
        opacity: 1;
      }
    }
  }
  .v-card__text span {
    font-size: 0.8rem;
  }
  .event-title {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }
</style>
