<template>
  <v-row class="navegation m-0 justify-content-center">
    <v-btn
      class="bg-transparent text-center"
      color="primary"
      elevation="0"
      v-for="button in buttons1"
      :key="button.title"
      :href="button.link"
      style="font-size: 0.8rem"
    >
      {{ button.title }}
    </v-btn>
  </v-row>
</template>
<script>
  export default {
    data() {
      return {
        buttons1: [
          {
            icon: 'fa fa-search',
            title: 'Guía de Franquicias',
            link: '/franquicias',
          },
          {
            icon: 'fa fa-search',
            title: 'Asociaciones de Franquicias',
            link: '/asociaciones-franquicias',
          },
          {
            icon: 'fa fa-search',
            title: 'Consultores',
            link: '/consultores',
          },
          {
            icon: 'fa fa-search',
            title: 'Proveedores',
            link: '/proveedores',
          },
          {
            icon: 'fa fa-search',
            title: 'Oferta inmobiliaria',
            link: '/oferta-inmobiliaria',
          },
        ],
        buttons2: [
          {
            icon: 'fa fa-search',
            title: 'Noticias',
            link: '/',
          },
          {
            icon: 'fa fa-search',
            title: 'Eventos',
            link: '/',
          },
          {
            icon: 'fa fa-search',
            title: 'Blog',
            link: '/',
          },
          {
            icon: 'fa fa-search',
            title: 'Foro',
            link: '/',
          },
        ],
      }
    },
  }
</script>
<style lang="scss" scoped>
  // Responsive Variables
  $xlmax-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
  $laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
  $desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $large-mobile: 'only screen and (max-width: 767px)';
  $small-mobile: 'only screen and (max-width: 575px)';
  $extra-small-mobile: 'only screen and (max-width: 479px)';

  .navegation {
    background-color: #003a56;
    column-count: 5;
    padding: 5px;
  }

  .message {
    font-size: 23px;
    width: 70%;
    margin: auto;
  }

  .custom-buttom {
    flex: 0 0 20%;
    max-width: 20%;

    padding: 0px auto 5px auto;
    min-height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border-radius: 10px;*/
    background: transparent;
    border: 1px solid #fff;
  }

  @media #{$desktop-device} {
    .custom-buttom {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  @media #{$tablet-device} {
    .custom-buttom {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }

  @media #{$large-mobile} {
    .message {
      font-size: 16px;
      width: 90%;
      margin: auto;
    }
    .custom-buttom {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }

  @media #{$small-mobile} {
    .custom-buttom {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .custom-buttom:hover {
    cursor: pointer;
    background-color: #141d38 !important;
    transform: scale(1.1);
  }
</style>
