<template>
  <div>
    <!-- Banner -->
    <home-head-banner
      class="mobile-banner"
      :bannerItems="filterItems('mobile')"
      @showModal="$modal.show('videoModal')"
    ></home-head-banner>

    <home-head-banner
      class="pc-banner"
      :bannerItems="filterItems('desktop')"
      @showModal="$modal.show('videoModal')"
    ></home-head-banner>

    <navegation v-if="$vuetify.breakpoint.mdAndUp" />

    <!-- Seccion superior 12 columnas -->
    <outstanding-companies :country="country" :outstanding="1" :type="0" />

    <div class="row mx-3" style="margin: 0px 2%">
      <div class="col-lg-9 col-md-9 col-sm-12">
        <topics-tabs id="actualidad" />
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12" style="padding: 0px; margin: 0px">
        <ads-section-3 :ads="v1Ads"></ads-section-3>
      </div>
    </div>

    <div class="pc" style="margin: 40px 0px">
      <a href="https://cogfranquicias.com/#cloud" target="_blank" rel="noopener">
        <img
          :src="'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/6b2441d00f2ad3809b330eec95296508.jpg'"
          style="background-size: contain; width: 100%"
        />
      </a>
    </div>

    <home-video-carousel :videos="videoCarousel"></home-video-carousel>

    <div class="mobile" style="margin: 40px 0px">
      <a href="https://cogfranquicias.com/#cloud" target="_blank" rel="noopener">
        <img
          :src="'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/a07b41e2dba860be7aecefd2fc136639.jpg'"
          style="background-size: contain; width: 100%"
        />
      </a>
    </div>

    <div class="container" style="margin: 40px 2%; max-width: -webkit-fill-available">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-12" style="padding: 0px; margin: 0px">
          <outstanding-companies-3
            :title="'Proveedores'"
            :outstanding="1"
            :country="country"
            :type="3"
          />
        </div>
        <div class="col-lg-8 col-md-7 col-sm-12" style="padding: 0px; margin: 0px">
          <master-franchise
            :title="'Franquicias Master Disponibles'"
            :country="country"
            :type="0"
            :master="true"
          />
        </div>
      </div>
    </div>

    <horizontal-ads-section
      :ads="h2Ads"
      style="margin: 40px 2%; max-width: -webkit-fill-available"
    ></horizontal-ads-section>

    <v-row
      :no-gutters="true"
      style="
        align-items: stretch;
        justify-content: center;
        margin: 40px 2%;
        max-width: -webkit-fill-available;
      "
    >
      <div class="col-12 col-md-8 col-lg-9 px-4">
        <glosary-tree class=""></glosary-tree>
      </div>
      <div class="col-10 col-sm-8 col-md-4 col-lg-3">
        <home-infographic-gallery
          @showModal="$modal.show('infographicModal')"
        ></home-infographic-gallery>
      </div>
    </v-row>

    <home-cog-banner></home-cog-banner>

    <div class="pc my-4">
      <img
        :src="'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/4c6b6e21457caa74b38358dc403b3ede.jpg'"
        style="background-size: contain; width: 100%"
      />
      <a
        href="/sufranquicia"
        class="btn btn-primary"
        style="
          position: absolute;
          top: 70%;
          background-color: #5564a2;
          border-color: #5564a2;
          color: #fff;
        "
        target="_blank"
        >Ver revista</a
      >
    </div>
    <div class="mobile my-4">
      <img
        :src="'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/b9bc8dbf14d66983daab4ce034b81e22.jpg'"
        style="background-size: contain; width: 100%"
      />
      <a
        href="/sufranquicia"
        class="btn btn-primary"
        style="
          position: absolute;
          top: 10%;
          background-color: #5564a2;
          border-color: #5564a2;
          color: #fff;
        "
        target="_blank"
        >Ver revista</a
      >
    </div>

    <companies-carousel
      :country="country"
      :type="0"
      style="margin: 0px 2%; max-width: -webkit-fill-available"
    />

    <div id="contact_form" class="row" style="margin: 40px 2%">
      <div
        id="ilustration"
        class="col-lg-6 col-md-6 col-sm-12"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: inherit;
        "
      >
        <!-- <img
          src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/77e23f5272edec1491ce0784eb31c98e.gif"
          style="margin: 5% 10%; height: fit-content"
        /> -->

        <!-- https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/81eab96801df7e8a34c918abe1489e0b.webm -->
        <video autoplay loop muted style="width: inherit; max-width: 500px">
          <source
            src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/8a0315032ebc4637f17e436726e93056.webm"
            type="video/webm"
          />
          <source
            src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/49bb42f3c95e3839e05d3fd4abd1ad25.mp4"
            type="video/mp4"
          />
          El navegador no soporta el reproductor de video de HTML5
        </video>
      </div>
      <div id="contacto" class="col-lg-6 col-md-6 col-sm-12">
        <contact-form
          :company_id="0"
          :country="country"
          :title="'Comunícate con nosotros:'"
          style="margin-top: 5%"
        />
      </div>
    </div>
    <div
      class="container mb-0 mt-5"
      style="margin: 0px 2%; max-width: -webkit-fill-available"
    >
      <h3 class="text-center mb-0">Representaciones Internacionales</h3>

      <flag-scroll />
      <whatsapp />
    </div>
    <modal
      id="vimeo-modal"
      name="videoModal"
      :width="'90%'"
      :height="'70%'"
      style="background-color: #00000050"
    >
      <iframe
        src="https://player.vimeo.com/video/721489644?h=4aedc383ae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        title="XPO STAND.mp4"
      >
      </iframe>
      <v-btn
        @click="$modal.hide('videoModal')"
        style="
          position: absolute;
          top: 0;
          right: 0;
          font-size: 2rem;
          color: #fff;
          background: transparent;
        "
      >
        <v-icon style="font-size: 2rem">mdi-close</v-icon>
      </v-btn>
    </modal>
  </div>
</template>

<script>
  import MasterFranchise from 'Components/globalFrontendComponents/MasterFranchise'
  import Navegation from 'Components/globalFrontendComponents/Navegation'
  import ContactForm from 'Components/globalFrontendComponents/ContactForm'
  import AdsSection2 from 'Components/globalFrontendComponents/AdsSection2'
  import AdsSection3 from 'Components/globalFrontendComponents/AdsSection3'
  import ListingCompanies from 'Components/globalFrontendComponents/ListingCompanies'
  import TopicsTabs from 'Components/globalFrontendComponents/TopicsTabs'
  import OutstandingCompanies from 'Components/globalFrontendComponents/OutstandingCompanies'
  import ConsultingSection from 'Components/globalFrontendComponents/ConsultingSection'
  import OutstandingCompanies3 from 'Components/globalFrontendComponents/OutstandingCompanies3'
  import CompaniesCarousel from 'Components/globalFrontendComponents/CompaniesCarousel'
  import FlagScroll from 'Components/globalFrontendComponents/FlagScroll'
  import Whatsapp from '@/components/globalFrontendComponents/WhatsApp'
  import GlosaryTree from 'Components/globalFrontendComponents/GlosaryTree'
  import HomeInfographicGallery from '@/components/globalFrontendComponents/HomeInfographicGallery'
  import HomeCogBanner from '@/components/globalFrontendComponents/HomeCogBanner'
  import HomeVideoCarousel from '@/components/globalFrontendComponents/HomeVideoCarousel'
  import HomeHeadBanner from '../../components/globalFrontendComponents/Header1.vue'

  export default {
    name: 'Home',
    props: ['country'],
    data() {
      return {
        intervalFunction: '',
        menu: true,
        date: new Date().toISOString(),
        flag: {},
        location: '',
        h1Ads: [],
        h2Ads: [],
        v1Ads: [],
        v2Ads: [],
        words: [],
        word: {},
        controls: true,
        autoplay: true,
        showModal: '',
        optionsBannerModal: {
          responsive: true,
        },
        horizontal2Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        vertical1Ads: {
          limit: 2,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        bannerItems: [
          {
            id: 'first-banner',
            type: 'desktop',
            title: 'PORTAL ESPECIALIZADO EN FRANQUICIAS',
            subtitle:
              'Conoce las novedades que están a tu alcance en torno al <br>MUNDO DE LOS NEGOCIOS<br/>',
            btnText: 'MIRA LO NUEVO AQUÍ',
            route:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/9e2ec7ffd75d6d0f3f3a599ff0de64f7.jpg',
          },
          {
            id: 'second-banner',
            type: 'desktop',
            title:
              'El Sistema de Negocios con <br/>MAYOR RENTABILIDAD en el mundo:<br/>LAS FRANQUICIAS',
            subtitle: 'Encuentra las franquicias más<br>destacadas de la región',
            btnText: 'Guía de Franquicias',
            route:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/3a15419564966538925e7f50cd393ecd.jpg',
          },
          // {
          //   id: 'third-banner',
          //   type: 'desktop',
          //   // title:
          //   //   'El Sistema de Negocios con <br/>MAYOR RENTABILIDAD en el mundo:<br/>LAS FRANQUICIAS',
          //   // subtitle: 'Encuentra las franquicias más<br>destacadas de la región',
          //   // btnText: 'Guía de Franquicias',
          //   // route:
          //   //   'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/3a15419564966538925e7f50cd393ecd.jpg',
          // },
          {
            id: 'first-banner',
            type: 'mobile',
            title: 'PORTAL ESPECIALIZADO EN FRANQUICIAS',
            subtitle:
              'Conoce las novedades que están a tu alcance en torno al <br>MUNDO DE LOS NEGOCIOS<br/>',
            btnText: 'MIRA LO NUEVO AQUÍ',
            route:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/28a2aa823b1922cb46613f5c101a9d93.jpg',
          },
          {
            id: 'second-banner',
            type: 'mobile',
            title:
              'El Sistema de Negocios con MAYOR RENTABILIDAD en el mundo: LAS FRANQUICIAS',
            subtitle: 'Encuentra las franquicias más destacadas de la región',
            btnText: 'Guía de Franquicias',
            route:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/7b8a8ee9fdf37b9f57b38db7c01f4cf2.jpg',
          },
        ],

        videoCarousel: [
          {
            index: 0,
            src:
              'https://player.vimeo.com/video/605608599?h=1dd319042d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
            title: 'FIAF',
          },
          {
            index: 1,
            src:
              'https://player.vimeo.com/video/605222394?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=c810700c8b%22',
            title: 'Café con Profranquicias',
          },
          {
            index: 2,
            src:
              'https://player.vimeo.com/video/605229031?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=25dee94609%22',
            title: '¡LikeU Creative, tu agencia digital!',
          },
          {
            index: 3,
            src:
              'https://player.vimeo.com/video/721489644?h=4aedc383ae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
            title: 'VISTA CORONA FRANCHISE',
          },
          {
            index: 4,
            src:
              'https://player.vimeo.com/video/721508520?h=940940c458&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
            title: 'Consultoría Diagnostico WOKI-XPOFranchise',
          },
        ],
      }
    },
    components: {
      FlagScroll,
      ListingCompanies,
      Navegation,
      AdsSection3,
      ConsultingSection,
      HorizontalAdsSection: AdsSection2,
      OutstandingCompanies,
      OutstandingCompanies3,
      CompaniesCarousel,
      ContactForm,
      MasterFranchise,
      TopicsTabs,
      Whatsapp,
      GlosaryTree,
      HomeHeadBanner,
      HomeInfographicGallery,
      HomeCogBanner,
      HomeVideoCarousel,
    },
    watch: {
      country(newValue, oldValue) {
        this.horizontal2Ads.countryId = newValue.id
        this.vertical1Ads.countryId = newValue.id
        this.getAdvertisements()
      },
    },
    computed: {
      countryId() {
        return this.country.id
      },
    },
    created() {
      this.getAdvertisements()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      adsTimer() {
        this.intervalFunction = setInterval(this.getAdvertisements, 60000)
      },
      async getAdvertisements() {
        const h1LimitStr = this.horizontal1Ads
          ? '&horizontal1[limit]=' + this.horizontal1Ads.limit
          : '&horizontal1[limit]=0'
        const h1CtryStr = this.horizontal1Ads
          ? '&horizontal1[country_id]=' + this.horizontal1Ads.countryId
          : '&horizontal1[country_id]=235'
        const h1OutStr = this.horizontal1Ads
          ? '&horizontal1[outstanding]=' + this.horizontal1Ads.outstanding
          : '&horizontal1[outstanding]=0'
        const h2LimitStr = this.horizontal2Ads
          ? '&horizontal2[limit]=' + this.horizontal2Ads.limit
          : '&horizontal2[limit]=0'
        const h2CtryStr = this.horizontal2Ads
          ? '&horizontal2[country_id]=' + this.horizontal2Ads.countryId
          : '&horizontal2[country_id]=235'
        const h2OutStr = this.horizontal2Ads
          ? '&horizontal2[outstanding]=' + this.horizontal2Ads.outstanding
          : '&horizontal2[outstanding]=0'
        const v1LimitStr = this.vertical1Ads
          ? '&vertical1[limit]=' + this.vertical1Ads.limit
          : '&vertical1[limit]=0'
        const v1CtryStr = this.vertical1Ads
          ? '&vertical1[country_id]=' + this.vertical1Ads.countryId
          : '&vertical1[country_id]=235'
        const v1OutStr = this.vertical1Ads
          ? '&vertical1[outstanding]=' + this.vertical1Ads.outstanding
          : '&vertical1[outstanding]=0'
        const v2LimitStr = this.vertical2Ads
          ? '&vertical2[limit]=' + this.vertical2Ads.limit
          : '&vertical2[limit]=0'
        const v2CtryStr = this.vertical2Ads
          ? '&vertical2[country_id]=' + this.vertical2Ads.countryId
          : '&vertical2[country_id]=235'
        const v2OutStr = this.vertical2Ads
          ? '&vertical2[outstanding]=' + this.vertical2Ads.outstanding
          : '&vertical2[outstanding]=0'

        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisement-management/get-advertisements?' +
              h1LimitStr +
              h1CtryStr +
              h1OutStr +
              h2LimitStr +
              h2CtryStr +
              h2OutStr +
              v1LimitStr +
              v1CtryStr +
              v1OutStr +
              v2LimitStr +
              v2CtryStr +
              v2OutStr
          )
          .then((response) => {
            this.h1Ads = response.data.horizontal1
            this.h2Ads = response.data.horizontal2
            this.v1Ads = response.data.vertical1
            this.v2Ads = response.data.vertical2
          })
          .catch((error) => {})
      },
      filterItems(type) {
        return this.bannerItems.filter((item) => item.type == type)
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
  }
</script>

<style lang="scss">
  // Responsive Variables
  $xlmax-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
  $laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
  $desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $large-mobile: 'only screen and (max-width: 767px)';
  $small-mobile: 'only screen and (max-width: 575px)';
  $extra-small-mobile: 'only screen and (max-width: 479px)';

  #inspire > .v-application--wrap {
    min-height: initial;
    max-height: 100%;
  } //Este estilo es para sobre escribir uno que inserta el copmonente del glosary-tree y poder mantener el height deseado

  #vimeo-modal > div {
    background: transparent;
    box-shadow: none;
  }
  .titlebar {
    position: relative;
    background-size: cover;
    background-position: center center;
    pointer-events: all;
    background-color: #293c63;
    color: #fff;
  }
  .mobile-handler {
    display: none;
  }
  .pc-handler {
    display: flex;
  }
  .pc {
    position: relative;
    background-size: cover;
    display: flex;
    justify-content: center;
  }
  .pc2 {
    background-image: url('https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/9c8cedbdeea575c302bac61dafddd1fd.jpg');
    height: 100%;
    padding: 7rem 0rem;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .mobile {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 599px) {
    #ilustration {
      padding: 10% 0;
      img {
        width: 100%;
      }
    }
  }
  @media #{$small-mobile} {
    .pc {
      display: none;
    }
    .mobile {
      display: flex;
    }
    .mobile-handler {
      display: flex;
    }
    .pc-handler {
      display: none;
    }
  }
  @media #{$large-mobile} {
    .pc {
      display: none;
    }
    .mobile {
      display: flex;
    }
    .mobile-handler {
      display: flex;
    }
    .pc-handler {
      display: none;
    }
  }
  @media #{$tablet-device} {
    .pc {
      display: none;
    }
    .mobile {
      display: flex;
    }
    .mobile-handler {
      display: flex;
    }
    .pc-handler {
      display: none;
    }
  }
  @media #{$desktop-device} {
    .pc {
      display: flex;
    }
    .mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 575px) {
    .pc-banner {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) {
    .mobile-banner {
      display: none;
    }
  }
</style>
