<template>
  <div class="container">
    <div class="row" v-if="companies.length == 0">
      <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5" v-for="index in 5" :key="index">
        <v-skeleton-loader
          :boilerplate="boilerplateActive"
          class="mx-auto"
          type="image"
        ></v-skeleton-loader>
      </div>
    </div>
    <slick :options="slickOptions" v-else :key="index" class="category-carouse1">
      <template v-for="company in companies" class="item">
        <div class="listing-item-container" :key="company.id">
          <div class="listing-item">
            <div class="listing-item-content">
              <a :href="'/empresa/' + company.link" class="image-container">
                <img
                  v-if="!company.logo"
                  src="/static/images/generico.png"
                  :alt="company.name"
                />
                <img v-else :src="company.logo.route" :alt="company.name" />
              </a>
            </div>
          </div>
        </div>
      </template>
    </slick>
  </div>
</template>
<script>
  import Slick from 'vue-slick'
  export default {
    props: ['country', 'type'],
    components: {
      Slick,
    },
    data() {
      return {
        index: 1,
        intervalFunction: '',
        companies: [],
        loading: true,
        boilerplateActive: false,
      }
    },
    watch: {
      country(newValue, oldValue) {
        this.getCompanies()
      },
      type(newValue, oldValue) {
        this.getCompanies()
        this.adsTimer()
      },
    },
    created() {
      this.getCompanies()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      adsTimer() {
        this.intervalFunction = setInterval(this.getCompanies, 60000)
      },
      async getCompanies() {
        let countryStr = ''
        let limitStr = '&limit=30'
        // let outstangingStr = ''
        if (this.country && this.country.id) {
          countryStr = '&country_id=' + this.country.id
        }
        // if (this.outstanding && this.outstanding == 1) {
        //   outstangingStr = '&outstanding=1'
        // }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-list?random=1&active=1&type=' +
              this.type +
              countryStr +
              limitStr
          )
          .then((response) => {
            this.companies = response.data
            if (this.companies.length === 0) {
              this.boilerplateActive = true
            }
            this.index++
            this.loading = false
          })
      },
    },
    computed: {
      slickOptions() {
        return {
          centerMode: true,
          centerPadding: '0%',
          slidesToShow: 6,
          slidesToScroll: 0,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 1000,
          infinite: true,
          variableWidth: false,
          responsive: [
            {
              breakpoint: 1441,
              settings: {
                centerPadding: '0px',
                slidesToShow: 6,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                centerPadding: '0px',
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                centerPadding: '0px',
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerPadding: '0px',
                slidesToShow: 2,
              },
            },
          ],
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
  }
</script>
<style lang="css" scoped>
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: contents;
    height: inherit;
  }
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .fullwidth-slick-carousel .slick-slide {
    pointer-events: all !important;
  }
  .category-carouse1 {
    pointer-events: all !important;
    height: inherit;
  }

  .fullwidth-slick-carousel.category-carousel .slick-slide,
  .container,
  .listing-item-container,
  .listing-item {
    /* margin: 24px 0 !important; */
    height: 200px;
  }
  .container {
    margin: 20px 0;
    padding: 0;
  }
  .listing-item-container {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  .listing-item {
    height: inherit;
    box-shadow: none;
    /* box-shadow: 0px 4px 10px 2px rgb(0 0 0 / 20%);
      border-radius: 5px; */
  }
  .listing-item-content {
    height: inherit;
    padding: 0px;
  }
  .image-container {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-container img {
    /* margin: 0 !important; */
    max-width: 80%;
    max-height: 100%;
    /* max-height: 80%; */
  }
</style>
